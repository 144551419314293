<template lang="pug">
.tab-rows 
  .tab-rows__data
    .tab-rows__loader(v-if="loading")
      q-spinner(color="primary", size="3em")
    .tb-rows__data-content(v-else)
      .tab-rows__header(v-if="loadColumns", :class="`level-${level}`")
        .tab-rows__header__title(
          v-for="(item, index) in rowsData.columns",
          :key="item.name",
          :style="getTabRowsHeaderTitleStyle(item)",
          @click="changeSort(item.name, index)"
        ) 
          Transition(name="rotate", mode="out-in")
            inline-svg.tab-rows__sort-icon(
              v-if="index !== 0",
              :src="getSortIcon(item.name)",
              :key="item.name + getSortIcon(item.name)"
            )
          span.tab-rows__header__name {{ item.label }}
        div(style="width: 2%")
      .tab-rows__empty(v-if="rowsData.items.length === 0") Нет доступных записей
      .tab-rows__items(:class="getTabRowsItemsClass()")
        .tab-rows__items-wrapper
          expansion-row-item(
            v-for="(el, index) in rowsData.items",
            :values="el",
            :columns="rowsData.columns",
            :level="level",
            :key="el.id",
            :query="query",
            :lastItem="isExpansionRowLastItem(index)",
            :levelName="levelName"
          )
        .tab-rows__items-loader(v-if="loadingMore")
          q-spinner(color="primary", size="3em")

        .tab-rows__observe-visibility(v-observe-visibility="reachedEndOfList")
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch, computed } from "vue";
import _ from "lodash";
import { useEmitter } from "@/services/useEmitter";
import { backend } from "@/api";
import { useStore } from "@/store";
import { generateFiltersParams } from "@/services/generateFiltersParams";

import DownArrow from "@/assets/icons/grid/down_arrow.svg";
import UpArrow from "@/assets/icons/grid/up_arrow.svg";

import ExpansionRowItem from "./ExpansionRowItem.vue";

import { handleError } from "@/services/handleErrors";

const props = defineProps({
  /**
   * Путь для запроса получения данных
   * Пример: `"/api/v3/expenses_dashboard/equipments"`
   */
  path: {
    type: String,
    required: true,
  },
  /**
   * Значение в фильтре поиска
   * Пример: `"abcd"`
   */
  query: {
    type: String,
    default: "",
  },
  /**
   * На каком уровне сейчас находимся. Увеличивается на 1 за каждое углубление в экспансию
   * Пример: `0`
   */
  level: {
    type: Number,
    default: 0,
  },
  /**
   * Условие нужно ли отображать колонки
   * Пример: `true`
   */
  loadColumns: {
    type: Boolean,
    required: true,
    default: false,
  },
  /**
   * Параметры фильтра для передачи дочерним компонентам для запроса заявок: `{ type: "ppr_equipment_id", value: 376 }`.
   * Где `value` - уникальный идентификатор, `type` - тип уровня экспансии для фильтра заявок.
   */
  nextLevelFilter: {
    type: Object,
    required: false,
    default: () => {},
  },
  /**
   * Значение текущего уровня в экспансии. Нужно для передачи дочернему компоненту для получение иконок в заголовках
   * Пример: `"Objects"`
   */
  levelName: {
    type: String,
    required: false,
    default: () => "Objects",
  },
  /**
   * Название текущего грида
   * Пример: `"expenses_dashboard"`
   */
  grid: {
    type: String,
    required: false,
    default: () => "expenses_dashboard",
  },
});

const emitter = useEmitter();
const store = useStore();

const perPage = 20;
const marginByLevel = 16;

const page = ref(1);
const totalCount = ref(0);
const loadingMore = ref(false);
const loading = ref(true);
const sort = ref(null);
const issueActions = ref({});

const rowsData = ref({
  columns: [],
  items: [],
});

const hasNextPage = computed(() => page.value < totalCount.value / perPage);
const filters = computed(() =>
  store.state.grid[props.grid]?.filters ? generateFiltersParams(store.state.grid[props.grid].filters) : {},
);
const query = computed(() => store.state.grid[props.grid]?.query);

const getColumnPaddingLeft = column => {
  return column.name === "title" && props.level > 0 ? props.level * marginByLevel : 0;
};

const getSortIcon = name => {
  if (sort.value?.name === name && sort.value?.value === true) {
    return UpArrow;
  } else {
    return DownArrow;
  }
};

const changeSort = async (name, index) => {
  if (index === 0) return;

  if (sort.value?.name === name) {
    sort.value.value = !sort.value.value;
  } else {
    sort.value = { name: name, value: true };
  }

  await refetchData();
};

const loadRows = async () => {
  try {
    const params = {
      filters: filters.value,
      pagination: {
        page: page.value,
        perPage: 20,
      },
    };
    if (props.nextLevelFilter) {
      params["filters"][props.nextLevelFilter.type] = props.nextLevelFilter.value;
    } else {
      params["query"] = query.value;
    }

    if (sort.value) {
      params["sort"] = { sortBy: sort.value.name, descending: sort.value.value };
    }

    const { data } = await backend.index(props.path, { params }, { encodeNestedData: true });
    totalCount.value = data.count;

    if (page.value === 1) {
      rowsData.value.columns = data.columns;
      rowsData.value.items = data.data;
    } else {
      rowsData.value.items = _.unionBy(rowsData.value.items, data.data, "id");
    }

    return true;
  } catch (e) {
    await handleError(e);
  } finally {
    loading.value = false;
    loadingMore.value = false;
  }
};

const refetchData = async (oldVal = null, newVal = null) => {
  if (((oldVal || newVal) && oldVal !== newVal) || (!oldVal && !newVal)) {
    page.value = 1;
    loading.value = true;
    await loadActions();
    await loadRows();
  }
};

const loadActions = async () => {
  try {
    const { data } = await backend.index("api/v3/expenses_dashboard/issues_actions", {});
    issueActions.value = data;
    initializeActionsGrids();
  } catch (e) {
    await handleError(e);
  }
};

const initializeActionsGrids = () => {
  store.commit("initialGridsState", { grid: "issues" });
  store.commit("initialGridsState", { grid: "ppr_issues" });
  store.commit("initialGrid", { grid_name: "issues", grid_key: "data" });
  store.commit("initialGrid", { grid_name: "ppr_issues", grid_key: "data" });
  const crud = issueActions.value.crud.issues;
  const pprCrud = issueActions.value.crud.ppr_issues;
  delete issueActions.value.crud;
  const actions = { ...issueActions.value };
  const pprActions = { ...issueActions.value };
  actions["crud"] = crud;
  pprActions["crud"] = pprCrud;
  store.commit("updateActions", { grid_name: "issues", grid_key: "data", actions: actions });
  store.commit("updateActions", { grid_name: "ppr_issues", grid_key: "data", actions: pprActions });
};

const reachedEndOfList = reached => {
  if (reached && !loading.value && hasNextPage.value && !loadingMore.value) {
    page.value++;
    loadMore();
  }
};

const loadMore = async () => {
  loadingMore.value = true;
  await loadRows();
};

const getTabRowsHeaderTitleStyle = item => {
  const style = {
    width: item.width,
    paddingLeft: `${getColumnPaddingLeft(item)}px`,
  };
  return style;
};

const isExpansionRowLastItem = index => {
  return rowsData.value.items.length - 1 === index;
};

const getTabRowsItemsClass = () => {
  return props.level === 0 ? "zero-level" : "";
};

onMounted(async () => {
  await loadActions();
  await loadRows();

  emitter.on("locale-changed", refetchData);
  emitter.on("refresh-expenses-dashboard-data", refetchData);
});

onBeforeUnmount(() => {
  emitter.off("locale-changed", refetchData);
  emitter.off("refresh-expenses-dashboard-data", refetchData);
});
</script>

<style lang="scss" scoped>
.tab-rows {
  margin-top: 20px;

  &__header {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 8px 16px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: var(--expenses-search-button-border-color);
    }

    &.level-2 {
      &::before {
        width: calc(100% - 32px);
      }

      .tab-rows__header__title:first-child {
        padding-left: 16px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #78839c;
      font-weight: 600;
      cursor: pointer;

      &:nth-child(1) {
        cursor: text;
      }
    }

    &__name {
      width: fit-content;
    }
  }

  &__items {
    &.zero-level {
      margin-top: 20px;
      > .tab-rows__items-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    &-loader {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__empty {
    text-align: center;
    margin: 50px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
}

:deep(.tab-rows__sort-icon) {
  width: 20px;
  height: 20px;

  path {
    fill: #78839c;
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease;
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(0.5turn);
}
</style>
