<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? `${main_class} valid-error` : main_class]", :id="name")
    multiselect(
      ref="multiselect",
      v-model="currentField",
      value="currentField",
      track-by="value",
      label="label",
      :multiple="multiple",
      placeholder="",
      :show-labels="false",
      @search-change="searchFn",
      :options="fieldOptions",
      :closeOnSelect="!multiple",
      :hideSelected="true",
      @open="multiselectHandler",
      :open-direction="open_direction",
      @select="onSelect",
      @remove="onRemove",
      :id="name",
      :disabled="localReadonly"
    )
      span(slot="noResult")
        .spinner-container.search-multiselect-spinner(v-if="search_in_process")
          q-spinner(color="primary", size="1.7em")
        i(v-else) {{ notifies.no_search_result }}

      span(slot="noOptions")
        i {{ notifies.no_options_list }}

      template(slot="afterList")
        div(v-if="optObject && optObject.count && hasNextPage()", style="text-align: center")
          div(v-observe-visibility="reachedEndOfList")
          span(style="padding: 10px") ...

    q-icon.cancel-select-field(name="cancel", v-if="canCancel()", @click.stop="resetField()")

  .valid-error-message(v-if="currentFieldIsInvalid") {{ currentErrorMessage }}
  .valid-error-message(v-else-if="forbidden") {{ forbiddenErrorMessage }}

  selected-items-form(
    ref="selected-items-form",
    v-if="selected_items_form && selected_items.length > 0",
    @set-selected-items="setSelectedItems",
    :parentData="{ data: selected_items_form, selected_items: selected_items, parent_name: this.name, grid: grid }"
  )
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import selectedItemsForm from "../selected_items_form/selectedItemsForm";

import { notifies } from "@/services/useLocales";
import { useEmitter } from "@/services/useEmitter";
import { handleError } from "@/services/handleErrors";

import _ from "lodash";

export default {
  components: {
    Multiselect,
    selectedItemsForm,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      multiple: this.parentData.data[0].multiple || false,
      value_data: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      staticData: this.parentData.data[0].data || [],
      refetchOnChange: this.parentData.data[0].refetch_on_change || false,
      req: this.parentData.data[0].require,
      depend_from: this.parentData.data[0].depend_from || {},
      watch: this.parentData.data[0].watch || {},
      open_direction: this.parentData.data[0].top_direction ? "top" : "bottom",
      selected_items_form: this.parentData.data[0].selected_items_form,
      readonly: this.parentData.data[0].readonly || false,
      forbidden: false,
      add_lonely_option: this.parentData.data[0].add_lonely_option || false,

      options_params: this.parentData.data[0].options_params || {},
      options_path: this.parentData.data[0].options_path,

      canPaste: this.parentData.data[0].can_paste || false,
      pasting: false,
      lastSearchQuery: "",
      timeout: null,

      optObject: {},
      options: [],
      fieldOptions: [],
      allFields: this.parentData.allFields || [],

      parentsCount: 0,

      nextPage: 2,
      pageSize: 20,
      loading: false,
      error: false,
      valid_error_message: null,
      main_class: "form-field form-multiselect",
      parent: undefined,
      parent_values: [],
      final_parent_values: [],

      selected_items_options: [],
      selected_items: [],

      search_in_process: false,

      emitter: useEmitter(),
    };
  },

  computed: {
    required: {
      get() {
        return this.req;
      },
      set(value) {
        this.req = value;
      },
    },

    value: {
      get() {
        return this.value_data;
      },
      async set(value) {
        this.value_data = value;
        await this.defaultLoad();
      },
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          return form_field["field"];
        } else {
          return undefined;
        }
      },
      set(value) {
        let result = {};
        result["field"] = value;
        result["invalid"] = this.invalid(value);
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    forbiddenErrorMessage() {
      return notifies.value.list_access_error;
    },

    localReadonly() {
      return this.readonly || this.forbidden;
    },

    fieldDependency() {
      return this.checkDependency();
    },

    // Errors messages we set from backend
    customErrorMessage() {
      const invalidFieldsErrors = this.currentForm.invalid_fields_errors;

      if (!invalidFieldsErrors) {
        return "";
      }

      return invalidFieldsErrors[this.name];
    },

    // Order of fields is important: valid_error_message must be first to preserve required errors
    currentErrorMessage() {
      return this.valid_error_message || this.customErrorMessage;
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  async mounted() {
    this.getParentContext();

    if (this.parentsCount === 0) await this.defaultLoad(false);

    if (this.watch && this.watch["parents"] && this.watch["parents"].length > 0) {
      this.watch["parents"].forEach(el => {
        this.$watch(
          () => {
            let form_field = this.currentForm[el.parent];
            if (form_field) {
              return form_field["field"];
            }
          },
          async (newValue, oldValue) => {
            this.parent = el.parent;

            let oldVal = this.valueIsObjectOrNot(oldValue);
            let newVal = this.valueIsObjectOrNot(newValue);

            // мы получаем родителя из первичных данных с бэка. До этого мы добавили к полю forbidden
            const parentField = this.allFields.find(el => el.name === this.parent);

            // мы должны дать обработчику войти в этот код, чтобы потом уменьшить каунтер parensCount, и чтобы дочерний селект мог сделать запрос
            const valueHasChanged =
              (oldVal && newVal && oldVal !== newVal) || (!oldVal && newVal) || (oldVal && !newVal);

            if (valueHasChanged || parentField.forbidden) {
              this.options_params["infinite_scroll"] = {
                page: 1,
                per_page: this.pageSize,
              };

              this.nextPage = 2;

              this.options_params[this.parent] = newVal;

              let dependencies = el["dependencies"];
              if (dependencies && dependencies.length > 0) {
                dependencies.forEach(dependency => {
                  let another_parent = this.currentForm[dependency];
                  if (another_parent) {
                    let val = this.valueIsObjectOrNot(another_parent["field"]);
                    this.options_params[dependency] = val;
                  }
                });
              }

              // TODO: добавить для других полей
              // Эта функция используется, чтобы дернуть метод после изменения родителя без запроса к api
              // Пример: убрать клинера, когда его родитель (строение) поменялся или очистился
              if (el["dep_fn"]) {
                this[el["dep_fn"]]();
              }

              if (Object.keys(el["parent_params"]).length > 0) {
                this.parentParamsRequest(this.options_params, el["parent_params"]);
              } else {
                if (oldValue !== newValue || parentField.forbidden) {
                  this.parentsCount -= 1;
                  if (this.parentsCount <= 0) {
                    await this.loadOptions(this.options_params, false);
                  }
                }
              }
            }
          },
        );
      });
    }
  },

  async beforeMount() {
    this.readOnlyField();
  },

  methods: {
    setField(val) {
      // this.value = val
    },

    setRequire(val) {
      this.required = val;
      let result = {};
      result["field"] = this.currentField;
      result["invalid"] = this.invalid(this.currentField);
      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

      this.$store.commit("resetFormFieldValue", {
        grid_name: this.grid,
        field: "invalid_fields",
        value: this.name,
      });
    },

    readOnlyField() {
      if (this.readonly) {
        this.main_class = this.main_class + " disabled-field";
      }
    },

    onSelect(val) {
      if (this.refetchOnChange && val?.value)
        this.emitter.emit("changeFormByField", { name: this.name, value: val.value });

      if (this.selected_items_form) {
        this.pushSelectedItem(val.value);
      }
    },

    onRemove(val) {
      if (this.selected_items_form && this.selected_items.length > 0) {
        this.resetSelectedItem(val.value);
      }
    },

    pushSelectedItem(id) {
      if (!this.selected_items.map(item => item.id).includes(id)) {
        this.selected_items.push(this.selected_items_options.find(el => el.id === id));
        this.$nextTick(() => {
          this.$refs["selected-items-form"].setSelectedItems(this.selected_items);
        });
      }
    },

    resetSelectedItem(id) {
      let data = this.selected_items.filter(el => el.id !== id);
      this.$nextTick(() => {
        if (this.$refs["selected-items-form"]) {
          this.$refs["selected-items-form"].setSelectedItems(data);
        }
      });
    },

    setSelectedItems(data) {
      this.selected_items = data;
    },

    resetField() {
      if (this.multiple) {
        this.currentField = [];
        if (this.selected_items_form && this.selected_items.length > 0) {
          this.selected_items = [];
        }
      } else {
        this.currentField = "";
      }
    },

    canCancel(val = this.currentField) {
      if (this.multiple) {
        return val && val.length > 0;
      } else {
        return val && val.value !== "";
      }
    },

    reachedEndOfList(reached) {
      if (reached && !this.error && !this.loading) {
        this.loading = true;

        if (this.nextPage < Math.ceil(this.currentField.length / this.pageSize)) {
          this.nextPage = Math.ceil(this.currentField.length / this.pageSize);
        }

        this.$nextTick(async () => {
          this.options_params["infinite_scroll"] = {
            page: this.nextPage,
            per_page: this.pageSize,
          };

          this.nextPage++;

          await this.loadOptions(this.options_params, true);
        });
      }
    },

    hasNextPage() {
      let lastPage = Math.ceil(this.optObject.count / this.pageSize);
      return this.nextPage <= lastPage;
    },

    async searchFn(query) {
      if (this.pasting) {
        return;
      }
      //проверка на количество измененных символов между последнним вводом в поиск и текущим
      //если больше 2-х символов разница, это вставка т.к. пользователь не может ввести 2 символа одним инпутом
      if (this.canPaste && this.lastSearchQuery.length < query.length - 1 && this.multiple) {
        this.lastSearchQuery = query;
        this.$refs.multiselect.deactivate();
        this.loading = true;
        this.pasting = true;
        await this.pasteObjects(query);
        return;
      }
      this.lastSearchQuery = query;
      let search_query = query.length > 0 ? query : null;
      this.options_params["search_query"] = search_query;

      this.options_params["infinite_scroll"] = {
        page: 1,
        per_page: this.pageSize,
      };

      this.nextPage = 2;

      this.startSearching(query);
    },

    async startSearching() {
      if (this.timeout) clearTimeout(this.timeout);

      this.loading = true;
      this.search_in_process = true;

      this.timeout = setTimeout(async () => {
        await this.loadOptions(this.options_params, false);
      }, 500);
    },

    async pasteObjects(query) {
      const optionsParams = {
        title: query.split(", "),
      };
      this.options_params["infinite_scroll"] = {
        page: 1,
        per_page: this.pageSize,
      };
      // очищение default_value который имеет значения пришедшие с бека в первом запросе form_data
      // чтобы бек не возвращал эти значения даже если их удалили на фронте
      this.options_params["default_value"] = null;
      const params = { ...optionsParams, ...this.options_params };
      params["title"] = query.split(", ");

      try {
        const { data } = await this.$backend.collection(
          `${this.$store.state.paths[this.options_path]}/collection`,
          params,
        );
        const additionalOptions = data.options.map(item => {
          const newItem = {
            label: item.title,
            value: item.id,
          };
          return newItem;
        });
        this.currentField = _.unionBy(this.currentField, additionalOptions, "value");
      } catch (err) {
        handleError(err);
      } finally {
        this.lastSearchQuery = "";
        this.loading = false;
        this.pasting = false;
      }
    },

    invalid(val = undefined) {
      if (this.required) {
        if (this.multiple) {
          if (val && val.length > 0) {
            this.valid_error_message = null;
            return false;
          } else {
            this.valid_error_message = this.notifies.not_empty;
            return true;
          }
        } else {
          if (val && val.value && val.value !== "") {
            this.valid_error_message = null;
            return false;
          } else {
            this.valid_error_message = this.notifies.not_empty;
            return true;
          }
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },

    async defaultLoad(setCurrentFieldToUndefined = true) {
      if (setCurrentFieldToUndefined) this.currentField = undefined;
      let params = {};
      if (this.options_params) {
        if (this.options_params["infinite_scroll"]) {
          this.options_params["infinite_scroll"] = {
            page: 1,
            per_page: this.pageSize,
          };

          this.nextPage = 2;
        }
        params = this.options_params;
      }

      await this.loadOptions(params, false);
    },

    parentParamsRequest(params, parent_params) {
      let path = this.$store.state.paths[parent_params["path"]] + parent_params["action"];

      this.$backend
        .index(path, { params: params })
        .then(({ data }) => {
          if (parent_params["fn"]) {
            this[parent_params["fn"]](data);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.error = true;
        });
    },

    getParentContext() {
      const localField = this.allFields.find(el => el.name === this.name);

      if (localField?.watch?.parents) {
        const parents = localField.watch.parents.map(el => ({
          parent: el.parent,
          params: Object.keys(el.parent_params),
        }));

        parents.forEach(el => {
          if (this.allFields.find(field => field.name === el.parent && field.value)) {
            if (el.params.length === 0) this.parentsCount += 1;
          }
        });
      }
    },

    // SECOND OPTION
    // getParentContext() {
    //   const localField = this.allFields.find(el => el.name === this.name);
    //   if (localField?.watch?.parents) {
    //     const parents = localField.watch.parents.map(el => el.parent);

    //     parents.forEach(el => {
    //       // исключили поля чекбоксов из этого выражения потому что у нас есть кейс в основных организациях
    //       // - при работе с полем saml_active добавление 1 к каунтеру ломает форму
    //       if (this.allFields.find(field => field.name === el && field.value && field.type !== 'checkbox')) {
    //         this.parentsCount += 1;
    //       }
    //     });
    //   }
    // },

    async loadOptions(params, infinite_scroll = true) {
      const parent_exist =
        this.parent &&
        (Array.isArray(params[this.parent]) ? params[this.parent].length > 0 : params[this.parent] !== "");

      if (this.currentField) {
        if (this.multiple) {
          params["default_value"] = this.currentField.map(el => el.value);
        } else {
          params["default_value"] = this.currentField.value;
        }
      } else if (this.value && !this.currentField) {
        params["default_value"] = this.value;
      }

      try {
        const data = (await this.loadContentPromise(params)).data;

        this.optObject = data;

        this.selected_items_options = _.unionBy(this.selected_items_options, this.optObject.options, "id");

        let for_select = this.optObject.options
          .map(el => {
            return [el["title"] ? el["title"] : el["full_name"], el.id];
          })
          .map(el => {
            return el.reduce((result, val, index, arr) => {
              result["label"] = arr[0];
              result["value"] = arr[1];
              return result;
            }, {});
          });

        this.optObject.options.map(el => el.id).includes();

        this.fieldOptions = infinite_scroll ? _.unionBy(this.fieldOptions, for_select, "value") : for_select;
        this.fieldOptions = this.fieldOptions;

        if (this.currentField) {
          if (this.multiple) {
            let val = this.currentField.map(el => el.value);

            if (val.length > 0) {
              val.forEach(id => {
                if (!this.fieldOptions.map(el => el.value).includes(id)) {
                  this.currentField = this.currentField.filter(el => el.value !== id);
                }
              });

              if (this.selected_items_form) {
                this.selected_items = [];
                val.forEach(id => {
                  this.pushSelectedItem(id);
                });
              }
            }

            if (this.add_lonely_option && this.currentField.length === 0 && this.fieldOptions.length === 1) {
              this.currentField = this.fieldOptions;
            }
          } else {
            if (!this.fieldOptions.map(el => el.value).includes(this.currentField.value)) {
              this.resetField();
            }

            if (this.add_lonely_option && !this.currentField && this.fieldOptions.length === 1) {
              this.currentField = this.fieldOptions[0];
            }
          }
        } else {
          this.$store.commit("createFormField", { grid_name: this.grid, field: this.name });

          if (this.value) {
            let result = {};

            if (this.multiple) {
              if (this.value.length > 0) {
                this.currentField = this.value
                  .filter(e => {
                    return e != null;
                  })
                  .map(e => {
                    return this.fieldOptions.find(opt => opt["value"] === e);
                  })
                  .filter(e => e !== undefined);

                if (this.selected_items_form) {
                  this.currentField
                    .map(el => el.value)
                    .forEach(id => {
                      this.pushSelectedItem(id);
                    });
                }
              } else {
                this.currentField = this.value;

                if (this.add_lonely_option && this.currentField.length === 0 && this.fieldOptions.length === 1) {
                  this.currentField = this.fieldOptions;
                }
              }
            } else {
              if (this.value !== "") {
                this.currentField = this.fieldOptions.find(e => e["value"] === this.value);
              } else {
                this.currentField = this.value;
              }
            }
          } else {
            if (this.add_lonely_option && this.fieldOptions.length === 1) {
              this.currentField = this.multiple ? this.fieldOptions : this.fieldOptions[0];
            } else {
              this.currentField = this.multiple ? [] : "";
            }
          }
        }
      } catch (error) {
        // мы устанавливаем обработку 403 ошибки, когда пользователь не имеет доступ по полиси к полю селекта,
        // мы должны установить forbidden, чтобы сделать поле недоступным для ввода,
        // установить значение и опции поля в пустые значения,
        // выполнить эмит, который установит в проп allFields forbidden для поля
        if (error?.response?.status === 403) {
          this.forbidden = true;
          this.currentField = this.multiple ? [] : "";
          this.optObject = { options: [], count: 0 };
          this.$emit("fieldIsForbidden", this.name);
        } else {
          await handleError(error);
          this.error = true;
        }
      } finally {
        this.loading = false;
        this.search_in_process = false;
        this.$emit("fields-loaded", ["select", this.name]);
      }
    },

    loadContentPromise(params) {
      if (this.options_path) {
        return this.$backend.collection(`${this.$store.state.paths[this.options_path]}/collection`, params);
      }

      return new Promise((resolve, _reject) => {
        resolve({ data: { options: this.staticData, count: this.staticData.length } });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/forms/fields/select";

.spinner-container.search-multiselect-spinner {
  position: relative;
  width: auto;
  top: 5px;
}

.valid-error-message {
  position: static;
}
</style>
