<template lang="pug">
.expansion-item__tabs-content
  .expansion-item__tabs-loading(v-if="loading")
    q-spinner(color="primary", size="3em")
  .expansion-item__tabs-list(v-if="tabs && !loading")
    q-tabs(v-model="currentTab", align="left", :style="getQtabsStyle()")
      q-tab.expansion-item__tab(v-for="tab in tabs", :name="tab.name", :label="tab.label", :key="tab.name")

    q-tab-panels(v-model="currentTab", :keep-alive="true")
      q-tab-panel(v-for="tab in tabs", :name="tab.name", :key="tab.name")
        expansion-rows(
          v-if="tab.name !== 'issues'",
          :path="getExpansionPath(tab.path)",
          :level="level + 1",
          :loadColumns="true",
          :nextLevelFilter="filter",
          levelName="equipments"
        )
        .expansion-item__content(v-else)
          expansion-row-item-content(:filter="filter", :level="level + 1")
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { handleError } from "@/services/handleErrors";

import expansionRows from "./ExpansionRows.vue";
import ExpansionRowItemContent from "./ExpansionRowItemContent.vue";

const props = defineProps({
  /**
   * Текущий уровень глубины экспансии.
   * Пример: `2`
   */
  level: {
    type: Number,
    required: true,
  },
  /**
   * Параметры фильтра для передачи дочерним компонентам для запроса заявок: `{ type: "ppr_equipment_id", value: 376 }`.
   * Где `value` - уникальный идентификатор, `type` - тип уровня экспансии для фильтра заявок.
   */
  filter: {
    type: Object,
    required: true,
  },
});

const emitter = useEmitter();

const tabs = ref();
const currentTab = ref();
const loading = ref(true);

const marginByLevel = 16;

const getTabs = async () => {
  try {
    loading.value = true;
    const { data } = await backend.index("/api/v3/expenses_dashboard/systems/tabs", {});
    tabs.value = data;
    currentTab.value = data.find(tab => tab.default).name;
  } catch (error) {
    handleError(error);
  } finally {
    loading.value = false;
  }
};

const getExpansionPath = path => {
  return `/api/v3/expenses_dashboard/${path}`;
};

const getQtabsStyle = () => {
  const style = {
    marginLeft: `${props.level * marginByLevel}px !important`,
  };
  return style;
};

onBeforeMount(async () => {
  await getTabs();
});
</script>

<style lang="scss">
.expansion-item__tabs-content {
  .expansion-item__tab {
    border-bottom: 2px solid #d7dae1;
    width: 100% !important;

    &.q-tab--active {
      border-bottom: 2px solid #5b7aff;
    }
  }
  .expansion-item__tabs-loading {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .expansion-item__content {
    max-height: 392px;
    overflow-y: auto;
    padding: 0 16px 16px;
    position: relative;
  }
}
</style>
