import strftime from "strftime";

export const handleDescriptionTd = text => {
  if (text) {
    return text
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/&quot;/g, "")
      .replace(/&nbsp;|&emsp;|&ensp;/g, " ");
  }
};

export const handleDateRow = (obj, key, format_str = undefined) => {
  let date, format, result;

  if (!obj[key]) return "";

  date = new Date(obj[key]);
  format = "%d.%m.%Y";

  if (format_str) {
    format = format_str;
  }

  result = strftime(format, date);
  return result;
};

export const handleDateTimeRow = (obj, key, format_str = undefined) => {
  let date, format, result;

  if (obj[key] == null) return "";

  date = new Date(obj[key]);
  format = "%d.%m.%Y, %H:%M:%S";

  if (format_str) {
    format = format_str;
  }

  result = strftime(format, date);
  return result;
};
