<template lang="pug">
q-dialog(v-model="visible", position="right", transition-show="slide-left", transition-hide="slide-right")
  q-card.entity-view
    q-card-section
      form-header(
        ref="form-header",
        @close-form="visible = false",
        :parentData="{ title: map_locales['entity_header_title'], title_icon: 'show_header.svg' }"
      )
    q-card-section
      component(:is="detailsComponent", :entity="entity")
</template>

<script>
import formHeader from "../../shared/forms/formHeader.vue";
import WorkerDetails from "./entity_details/Worker";
import BuildingDetails from "./entity_details/Building";
import VehicleDetails from "./entity_details/Vehicle";

export default {
  components: {
    formHeader,
  },
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      visible: !!this.entity,
    };
  },

  computed: {
    detailsComponent() {
      if (!this.entity) {
        return null;
      }

      const map = {
        worker: WorkerDetails,
        building: BuildingDetails,
        vehicle: VehicleDetails,
      };

      const component = map[this.entity.getType()];

      if (!component) {
        console.error(`No view component defined for entity ${this.entity.getType()}`);
      }

      return component;
    },
  },

  watch: {
    entity(newVal) {
      this.visible = !!newVal;
    },
    visible(newVal) {
      if (!newVal) {
        this.$emit("closed");
      }
    },
  },
};
</script>

<style lang="scss">
.entity-view {
  border-radius: 15px 0px 0px 15px !important;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden !important;

  background: var(--show-card-background);

  z-index: 1;
  right: 0px;
  top: 0px;
  width: 522px;
  min-height: 100%;
  height: 100%;
}

.entity-view__label {
  font-weight: bold;
  color: var(--header-title-color);
}

.entity-view__value {
  border: var(--field-border);
  border-radius: 8px;
  padding: 5px;
  color: var(--header-title-color);
}
</style>
