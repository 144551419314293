<template lang="pug">
#main(:class="[expand_class ? self_class + ' ' + expanded_class : self_class]")
  .spinner-container.form-spinner(v-if="check_auth_loading || !allDataLoaded")
    q-spinner(color="primary", size="3em")

  #modal-background

  .main-component(v-show="(!check_auth_loading && allDataLoaded) || not_auth_container")
    .navbar-container(:class="navbarClass", v-if="!check_auth_loading && isAuthenticated() && !not_auth_container")
      navbar(@set-loading="setNavbarLoading", @set-main-loading="setMainLoading", @expand-class="expandClass")

    q-btn.navbar-trigger(
      :icon="navbarTriggerIconName",
      flat,
      round,
      dense,
      @click="changeNavbarVisible",
      :class="navbarTrigerClass",
      v-if="!check_auth_loading && isAuthenticated() && !not_auth_container"
    )

    #main-container.main-container(
      :class="mainClass",
      v-if="!check_auth_loading && isAuthenticated() && !not_auth_container"
    )
      //div(class="trouble-shooting-container")
        div(class="trouble-shooting-message")
          span {{trouble_shooting_message}}

      router-view(
        @set-loading="setMainLoading",
        @expand-class="expandClass",
        :key="$route.fullPath",
        :location="getLocation()"
      )

      q-dialog(v-model="report_modal", persistent)
        report-link-area(:parentData="{ report: report }")

    .login-container(v-if="!isAuthenticated() && !not_auth_container")
      router-view(@set-loading="setMainLoading", :key="$route.fullPath")

    .not-auth-container(v-if="not_auth_container")
      router-view(@set-loading="setMainLoading", :key="$route.fullPath")

    .report-pending(v-if="isReportPending") {{ reportPendingMessage }}
</template>

<script>
import { useEmitter } from "@/services/useEmitter";

import Navbar from "./Navbar";
import reportLinkArea from "./helpers/reportLinkArea";
import channels from "@/components/shared/channels";

import i18n from "@/plugins/vue-i18n";

export default {
  channels: {
    ...channels,
    IssueCenterChannel: {
      received(data) {
        if (data.event === "create") {
          this.emitter.emit("createdNewIssue", data.issue);
        }
        if (data.event === "archive" || data.event === "destroy") {
          this.emitter.emit("archivedIssue", data.issue);
        }
        if (data.event === "update") {
          this.emitter.emit("updatedIssue", data.issue);
        }
        if (data.event === "update_finish_date_plane") {
          this.emitter.emit("updatedCalendarIssue", data);
        }
      },
    },
    IssueFiltersChannel: {
      received(data) {
        if (data.event === "create") {
          this.emitter.emit("createdNewCustomIssue", data);
        }
        if (data.event === "archive" || data.event === "destroy") {
          this.emitter.emit("archivedCustomIssue", data);
        }
        if (data.event === "update") {
          this.emitter.emit("updatedCustomIssue", data);
        }
      },
    },
  },
  components: {
    Navbar,
    reportLinkArea,
    channels,
  },
  data: function () {
    return {
      navbar_loading: true,
      main_loading: true,
      self_class: "main",
      emitter: useEmitter(),

      expand_class: false,
      expanded_class: "",

      intervals: [],
      report: {},
      report_modal: false,

      check_auth_loading: true,

      is_navbar_visible: true,

      trouble_shooting_message:
        "Уважаемые пользователи, в настоящий момент имеются проблемы на стороне провайдера, что приводит к сбоям в работе программы. Приносим свои извинения. Прикладываем все усилия для устранения проблемы.",

      isReportPending: !!this.report_id_waiting || false,
    };
  },

  computed: {
    allDataLoaded() {
      let main_loading = this.main_loading;
      return (
        (!this.isAuthenticated() && !main_loading) ||
        (this.isAuthenticated() && this.theme && !this.navbar_loading && !main_loading)
      );
    },

    not_auth_container() {
      return this.$store.state.not_auth_paths.includes(this.$route.path);
    },

    navbarTriggerIconName() {
      return this.is_navbar_visible ? "chevron_left" : "chevron_right";
    },

    navbarClass() {
      return {
        "navbar-container--enabled": this.is_navbar_visible,
        "navbar-container--disabled": !this.is_navbar_visible,
      };
    },

    mainClass() {
      return { "main-container--expand": !this.is_navbar_visible, "main-container--shrink": this.is_navbar_visible };
    },

    navbarTrigerClass() {
      return { "navbar-trigger--enabled": this.is_navbar_visible, "navbar-trigger--disabled": !this.is_navbar_visible };
    },

    reportPendingMessage() {
      return i18n.messages[this.current_locale]["reportIsWaiting"];
    },
  },

  watch: {
    current_user(newVal, oldVal) {
      if (newVal) {
        this.setTheme(newVal.theme);
      }
    },

    check_auth_loading(newVal, oldVal) {
      if (newVal === false) {
        if (this.current_user) {
          this.setTheme();
        }
        this.reportLogic();
      }
    },
  },

  created() {
    this.loadDefaultLocale();
    this.loadDefaultLocalesData();
    setTimeout(() => {
      this.checkQuery();
    }, 300);
  },

  beforeDestroy() {
    this.destroyIntervals();
  },

  mounted() {
    this.$cable.subscribe({ channel: "IssueCenterChannel", token: this.auth_token });
    this.$cable.subscribe({ channel: "IssueFiltersChannel", token: this.auth_token });
    this.$cable.subscribe({ channel: "AlertsChannel", token: this.auth_token });
  },

  methods: {
    changeNavbarVisible() {
      this.is_navbar_visible = !this.is_navbar_visible;
    },

    check_auth() {
      if (!this.not_auth_container) {
        this.$backend
          .index(this.$store.state.paths.user + "/current_theme", { params: {} })
          .then(({ data }) => {
            this.check_auth_loading = false;
          })
          .catch(error => {
            this.check_auth_loading = true;

            if (error.response) {
              this.reLogin(error.response.status);

              setTimeout(() => {
                this.check_auth_loading = false;
              }, 1500);
            }
          });
      } else {
        setTimeout(() => {
          this.check_auth_loading = false;
        }, 500);
      }
    },

    async checkQuery() {
      if (this.$route.query && this.$route.query.provider_token) {
        await this.signIn();
      } else {
        this.check_auth();
      }
    },

    async signIn() {
      const user = {
        provider_token: this.$route.query.provider_token,
      };
      try {
        const res = await this.$backend.sign_in(this.$store.state.paths.sign_in, {
          user,
        });
        this.$store.commit("logIn", { user: res.data.user, token: res.data.token });
        this.$router.push({
          name: "Dashboard",
          params: { facility_id: res.data.user.facility_ids[0], type: "issues" },
        });
        this.check_auth();
      } catch (error) {
        if (error.response && [401, 404].includes(error.response.status)) {
          this.reLoginRun();
          this.$router.replace({ query: null });
          this.check_auth();
          this.$cable.subscribe({
            channel: "IssueCenterChannel",
            token: this.auth_token,
          });
          this.$cable.subscribe({
            channel: "IssueFiltersChannel",
            token: this.auth_token,
          });
        }
      }
    },

    expandClass(data) {
      if (data) {
        this.expand_class = data.val;
        this.expanded_class = data.class;
      } else {
        this.expand_class = false;
        this.expanded_class = "";
      }
    },

    setNavbarLoading(val) {
      this.navbar_loading = val;
    },

    setMainLoading(val) {
      this.main_loading = val;
    },

    isAuthenticated() {
      return this.current_user && this.auth_token;
    },

    reportLogic() {
      this.$root.$on("init-report-intervals", data => {
        localStorage.setItem("report_id_waiting", data.id);
        this.initReportIntervals(data.id);
      });

      this.$root.$on("show-report-modal", data => {
        this.report = data.report;
        this.report_modal = data.value;
      });

      if (this.report_id_waiting) {
        this.initReportIntervals(this.report_id_waiting);
      }
    },

    getLocation() {},

    initReportIntervals(id) {
      this.destroyIntervals();
      this.isReportPending = true;
      this.intervals = [
        setInterval(() => {
          this.checkReportState(id);
        }, 20000),
      ];
    },

    checkReportState(id) {
      // Cleaning journal's manual verify report doesn't have id as it is created in background
      // In that case fallback to 0 since such id doen't exist
      const reportId = id || 0;

      this.$backend
        .show(this.$store.state.paths["report"], reportId, { params: {} })
        .then(({ data }) => {
          if (data["expired"] && data["link"]) {
            this.destroyIntervals();
            localStorage.removeItem("report_id_waiting");
            this.report = data;
            this.report_modal = true;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.destroyIntervals();
          localStorage.removeItem("report_id_waiting");
          this.$q.notify(this.notifies.report_error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/main.scss";
.main-component {
  position: relative;
}
.report-pending {
  position: absolute;
  top: 16px;
  width: 333px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 151px);
  background: #7c95ff;
  border-radius: 12px;
  color: white;
  animation: fade 6s infinite;
}

@keyframes fade {
  0% {
    background: #7c95ff;
  }
  10% {
    background: #7c95ff;
  }
  52% {
    background: #dee4ff;
  }
  58% {
    background: #dee4ff;
  }
  100% {
    background: #7c95ff;
  }
}
.navbar-trigger {
  position: fixed;
  top: 66px;
  left: 136px;
  z-index: 2000;
  background: var(--navbar-background-color);
  color: white;
  box-shadow: 0 0 18px -10px black;
  transition: all 0.2s;

  &--enabled {
    transition: all 0.2s;
    background: var(--navbar-background-color);
    color: white;
    left: 136px;
  }

  &--disabled {
    transition: all 0.2s;
    background: var(--main-background-color);
    color: var(--header-title-color);
    left: -14px;
  }

  i {
    margin-left: 1px !important;
  }
}
</style>
