import Vue from "vue";
import prints_css from "../../assets/styles/prints";
import compiledPrintQrImageCss from "!raw-loader!sass-loader!../../assets/styles/printQr/qr-image.scss";

Vue.mixin({
  computed: {
    theme() {
      return this.$store.state.theme.value;
    }, // реализовано в сервисах

    locales() {
      return this.$store.state.locales;
    }, // реализовано в сервисах

    default_locale() {
      return this.$store.state.account.default_locale;
    }, // реализовано в сервисах

    current_locale() {
      return this.current_user ? this.current_user.locale || this.default_locale : this.default_locale;
    }, // реализовано в сервисах

    current_user() {
      return this.$store.state.account.current_user;
    }, // реализовано в сервисах

    current_user_id() {
      return this.$store.state.account.current_user?.id;
    }, // реализовано в сервисах

    ui_prompts_showed() {
      return (this.current_user && this.current_user.ui_prompts_showed_at) || false;
    }, // реализовано в сервисах

    auth_token() {
      return this.$store.state.account.auth_token;
    }, // реализовано в сервисах

    allDataLoaded() {
      return !this.filter_data_loading;
    },

    report_id_waiting() {
      return localStorage.getItem("report_id_waiting");
    },

    notifies() {
      return this.locales["notifies"][this.current_locale];
    }, // реализовано в сервисах
  },

  methods: {
    loadDefaultLocale(params = {}) {
      this.$backend
        .index(this.$store.state.paths["default_locale"], { params: params })
        .then(({ data }) => {
          this.$store.commit("setDefaultLocale", data);
        })
        .catch(error => {
          console.log(error);
        });
    }, // реализовано в сервисах

    loadDefaultLocalesData(params = {}) {
      return this.$backend
        .index(this.$store.state.paths["default_locales"], { params: params })
        .then(({ data }) => {
          if (data && Object.keys(data).length > 0) {
            this.$store.commit("addLocales", data);
          }
        })
        .catch(error => {
          console.log(error);
        }); // реализовано в сервисах
    },

    destroyIntervals() {
      this.intervals.forEach(clearInterval);
      this.intervals = [];
      this.isReportPending = false;
    },

    setTheme(val = undefined) {
      // реализовано в сервисах
      // let theme = val || this.current_user.theme
      // this.$store.commit('changeTheme', theme)
      // document.documentElement.setAttribute('theme', theme)

      this.$backend
        .index(this.$store.state.paths["user"] + "/current_theme", { params: {} })
        .then(({ data }) => {
          if (!data) {
            data = "light";
          }
          let theme = val || data;
          this.$store.commit("changeTheme", theme);
          document.documentElement.setAttribute("theme", theme);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        });
    },

    print(attr) {
      if (attr && attr.html_element_id) {
        const id = attr.html_element_id;
        const css =
          attr.css_key == "print_equipment_tech_map"
            ? prints_css[attr.css_key] + compiledPrintQrImageCss
            : prints_css[attr.css_key];
        const title = attr.html_title;

        this.$PrintPlugin(id, css, title);
      }
    },

    temporaryFixSafariPopupBug(event, parent_id) {
      let parent = document.getElementById(parent_id);

      if (event === "open") {
        let field = document.getElementById(this.name);
        field.addEventListener(
          "click",
          e => {
            if (e.target.classList.contains("vdatetime")) {
              parent.style["overflow-y"] = "initial";
              parent.style["overflow-x"] = "initial";
            }
          },
          false,
        );
      } else if (event === "close") {
        parent.style["overflow-y"] = "auto";
        parent.style["overflow-x"] = "hidden";
      }
    },
  },
});
